@import url('https://fonts.cdnfonts.com/css/archivo');
@import url('https://fonts.cdnfonts.com/css/dejavu-sans');
@tailwind base;
@tailwind components;
@tailwind utilities;



html{
  width: 100vw;
  -ms-overflow-style: none;  
  scrollbar-width: none;
}

body{
  font-family: 'Archivo';
  color: #1b4965;
  background-color: #e6ebe0;
  width: full;
}
-webkit-scrollbar {
  display: none;
  background-color: #e6ebe0;
}

.tab {
  transition: background-color 0.3s ease, border-color 0.3s ease;
  border-radius: 0.25rem;
}
.shadow-div{
  box-shadow: 0px 0px #0b3954;
}
.shadow-div:hover{
  box-shadow: 6px 6px #0b3954;
  transition: ease-out 0.1s;
}
.tab:hover {
  background-color: var(--error-color-hover);
  border-color: black;
}

.carousel{
  cursor: url("data:image/svg+xml,%3csvg xmlns='./Assets/cursor.svg' width='32' height='32' ...") 16 16, pointer;
}

#herolal {
  width: 100%;
  height: 100vh; 
}
.education-shadow{
  box-shadow: 6px 6px #0b3954;
}